<template>
  <section>
    <button
      @click.prevent="toggleModal"
      :disabled="!item.extra_data.detail.actions?.download"
      :class="
        !item.extra_data.detail.actions?.download ? 'text-muted' : 'text-dark'
      "
      type="button"
      class="w-100 text-left v-btn v-btn--text theme--light v-size--default text-dark"
    >
      <span class="v-btn__content"> {{ $t("scanned_items") }}</span>
    </button>

    <!-- modal start -->
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="800px"
      class="overflow-hidden h-600px"
    >
      <v-card>
        <v-card-title class="custom-card-title">
          <h3 class="mb-0 font-weight-bolder">{{ $t("scanned_items") }}</h3>
        </v-card-title>
        <v-card-text>
          <v-card-text>
            <div class="scanned-block">
              <v-data-table
                :headers="headers"
                :items="tableData"
                v-model="selected"
                show-select
                disable-sort
                class="striped-table"
                item-value="id"
              >
                <template v-slot:item="{ item, index, select, isSelected }">
                  <tr :class="getClass(item, index)">
                    <td>
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          @click="onSelect(item)"
                          :checked="isSelected"
                        />
                      </div>
                    </td>
                    <td v-for="header in headers" :key="header.value">
                      <template v-if="header.value === 'action'">
                        <span class="mt-2">
                          <v-icon left @click="removeItem(item)">
                            mdi-delete
                          </v-icon>
                        </span>
                      </template>
                      <template v-else>{{ item[header.value] }}</template>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-row justify="center" class="align-items-end">
            <v-col class="d-flex justify-center">
              <button
                @click.prevent="toggleModal"
                type="submit"
                class="btn btn--export-filter px-5 py-3"
              >
                {{ this.$t("close") }}
              </button>
              <button
                @click.prevent="confirmItmes"
                type="submit"
                class="btn btn--print-upload px-5 py-3 ml-3"
              >
                {{ this.$t(`confirm_${title}`) }}
              </button>
              <button
                @click.prevent="bulkDelete"
                type="submit"
                class="btn btn-google px-3 py-3 ml-3"
              >
                {{ this.$t(`delete_items`) }}
              </button>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <!-- modal end -->
    </v-dialog>
  </section>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";

export default {
  name: "ConfirmItems",
  props: [
    "item",
    "getItemsUrl",
    "confirmItemsUrl",
    "headers",
    "responseObject",
    "title",
    "removeItemsUrl",
  ],
  data: () => ({
    dialog: false,
    serverData: null,
    tableData: [],
    selected: [],
  }),
  methods: {
    async toggleModal() {
      if (!this.dialog) {
        await this.loadServerData();
      } else {
        this.serverData = null;
        this.tableData = [];
        this.selected = [];
      }
      this.dialog = !this.dialog;
    },
    async loadServerData() {
      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        warehouse_id: this.selectedWarehouse,
        id: this.item.id,
      };

      try {
        const response = await ApiService.post(
          `warehouse_management/custom/${this.getItemsUrl}`,
          payload
        );
        const { data } = response;
        this.tableData = data[this.responseObject];
      } finally {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    async confirmItmes() {
      if (!this.selected.length) {
        SwalService.warningMessage({
          title: this.$t("warning"),
          html: this.$t("please_select_at_least_one_item"),
          icon: "warning",
          cancelButtonText: "OK",
          cancelButtonColor: "#FFA800",
          showConfirmButton: true,
        });
        return;
      }
      // api call
      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        warehouse_id: this.selectedWarehouse,
        id: this.item.id,
        scanned_record_ids: this.selected.map((item) => item.id),
      };

      try {
        await ApiService.post(
          `warehouse_management/custom/${this.confirmItemsUrl}`,
          payload
        );

        SwalService.successMessage({
          title: this.$t(this.$t("updated")),
          html: this.$t("updated_successfully"),
        });
        this.dialog = false;
      } finally {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    getClass(item, index) {
      let res = index % 2 === 0 ? "even-row" : "odd-row";
      if (item.expected_qty < item.scanned_qty + item.received_qty) {
        res += " bg-light-danger";
      }
      return res;
    },
    removeItem(item, isBulk = false) {
      SwalService.warningConditionMessage(
        {
          title: `Are you sure?`,
          html: `Do you want to remove ${
            isBulk ? "selected items" : item.sku_barcode
          }`,
          confirmButtonText: `Yes`,
        },
        () => {
          this.$store.commit(SET_PAGE_LOADING, true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            id: isBulk ? item[0].asn_id : item.asn_id,
            items: isBulk ? item.map((item) => item.id) : [item.id],
          };

          ApiService.post(
            `warehouse_management/custom/${this.removeItemsUrl}`,
            payload
          )
            .then(() => {
              SwalService.successMessage({
                title: this.$t("success"),
                html: this.$t("record_has_been_deleted_successfully"),
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });

              this.selected = [];
            })
            .then(() => {
              this.loadServerData();
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        }
      );
    },
    bulkDelete() {
      if (!this.selected.length) {
        SwalService.warningMessage({
          title: this.$t("warning"),
          html: this.$t("please_select_at_least_one_item"),
          icon: "warning",
          cancelButtonText: "OK",
          cancelButtonColor: "#FFA800",
          showConfirmButton: true,
        });
        return;
      }
      this.removeItem(this.selected, true);
    },
    onSelect(item) {
      const index = this.selected.findIndex((i) => i.id === item.id);
      if (index === -1) {
        this.selected.push(item);
      } else {
        const selectedCopy = this.selected;
        selectedCopy.splice(index, 1);
        this.selected = [...selectedCopy];
      }
    },
  },
  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
};
</script>

<style scoped>
.striped-table {
  width: 100%;
  border-collapse: collapse;
}

.striped-table tr {
  border-bottom: 1px solid #e0e0e0;
}

.striped-table .even-row {
  background-color: #f9f9f9;
}

.striped-table .odd-row {
  background-color: #ffffff;
}
.scanned-block {
  height: calc(90vh - 462px);
  overflow: auto;
}
</style>
